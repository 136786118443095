export default [
  'https://media.giphy.com/media/e6TR9n00dL3JS/giphy.gif',
  'https://media.giphy.com/media/h8NdYZJGH1ZRe/giphy.gif',
  'https://media.giphy.com/media/Zw3oBUuOlDJ3W/giphy.gif',
  'https://media.giphy.com/media/zXubYhkWFc9uE/giphy.gif',
  'https://media.giphy.com/media/NdP0HvA9NVmFi/giphy.gif',
  'https://media.giphy.com/media/37joYz7vrHTyM/giphy.gif',
  'https://media.giphy.com/media/W0Lb0xjMDFrWw/giphy.gif',
  'https://media.giphy.com/media/J0ySNzZ5APILC/giphy.gif',
  'https://media.giphy.com/media/PcnUjSEMBlpgk/giphy.gif',
  'https://media.giphy.com/media/vWdiIPTL5L2w/giphy.gif',
  'https://media.giphy.com/media/E4LWtyQ9KiToA/giphy.gif',
  'https://media.giphy.com/media/8ip0NDz8dtdzG/giphy.gif',
  'https://media.giphy.com/media/sfevs0OasgnO8/giphy.gif',
  'https://media.giphy.com/media/wpVM8uZMwThC0/giphy.gif',
  'https://media.giphy.com/media/149EV8wlV75ZQc/giphy.gif',
  'https://media.giphy.com/media/xT1XGQHjEkRdsggVXi/giphy.gif',
  'https://media.giphy.com/media/mnJRjgXZ2ooh2/giphy.gif',
  'https://media.giphy.com/media/M4Db1NwoYfdkI/giphy.gif',
  'https://media.giphy.com/media/eQOuohZTlop0I/giphy.gif',
  'https://media.giphy.com/media/133rd60nMOUezK/giphy.gif',
  'https://media.giphy.com/media/3o6MbaJ1QSZMD3qZ0c/giphy.gif',
  'https://media.giphy.com/media/Q5FQLasUIwG4HRhOxt/giphy.gif',
  'https://media.giphy.com/media/YqQ3BkBRtq1gOsPFDq/giphy.gif',
  'https://media.giphy.com/media/HJCGf7JdZl0A/giphy.gif',
  'https://media.giphy.com/media/3oEhmQuI7s0ct1GBX2/giphy.gif',
  'https://media.giphy.com/media/tyttpHfmLggMaUkIq9a/giphy.gif',
  'https://media.giphy.com/media/1R4SEVtD3MUaA/giphy.gif',
  'https://media.giphy.com/media/8JCwuk8n2Y6iI/giphy.gif',
  'https://media.giphy.com/media/PAujV4AqViWCA/giphy.gif',
  'https://media.giphy.com/media/K34FVrUx8ggyA/giphy.gif',
  'https://media.giphy.com/media/3o6Ztd5vx7mxQ4AkQU/giphy.gif',
  'https://media.giphy.com/media/3dggCmUhbi96FghapE/giphy.gif',
  'https://media.giphy.com/media/Av1ogAiI3H2xA1P0al/giphy.gif',
  'https://media.giphy.com/media/IgpAALi5hEv1IFmCrZ/giphy.gif',
  'https://media.giphy.com/media/l0MYF7e2EDJxYR1OE/giphy.gif',
  'https://media.giphy.com/media/3o6gDX6d9oCiWyZi9i/giphy.gif',
  'https://media.giphy.com/media/l46Cl6JOKu0fbiR3O/giphy.gif',
  'https://media.giphy.com/media/fteuyBB91K0ZA7Oc71/giphy.gif',
  'https://media.giphy.com/media/QJZow3Zt83AwEgMJXO/giphy.gif',
  'https://media.giphy.com/media/kEDvhNhBzyQCyO2pec/giphy.gif',
  'https://media.giphy.com/media/4JSAlSGyuKeUUIiLvq/giphy.gif',
  'https://media.giphy.com/media/RkHvBdHR34DdRkN3jh/giphy.gif',
  'https://media.giphy.com/media/1iVaMtJxWxBeHZEQ/giphy.gif',
  'https://media.giphy.com/media/wKzcnQD3LNQzHE03S9/giphy.gif',
  'https://media.giphy.com/media/Cr4W77QNPjJXa/giphy.gif',
  'https://media.giphy.com/media/2Li7hObp1BlBK/giphy.gif',
  'https://media.giphy.com/media/yxYsvyhMPfTG0/giphy.gif',
  'https://media.giphy.com/media/cewa5NekOzPUs/giphy.gif',
  'https://media.giphy.com/media/14oxyFFPeQAVqM/giphy.gif',
  'https://media.giphy.com/media/hXD3cypLkycW1hQTFz/giphy.gif',
  'https://media.giphy.com/media/iOz3p2txHIo4U/giphy.gif',
  'https://media.giphy.com/media/Y48Qr2Ntib9r5RQpeB/giphy.gif',
  'https://media.giphy.com/media/3ohfFp1ureujZgWSVa/giphy.gif',
  'https://media.giphy.com/media/7vQZanyufdRe0/giphy.gif',
  'https://media.giphy.com/media/AwkqAwhwqGzg4/giphy-downsized-large.gif',
  'https://media.giphy.com/media/ROwh79Fh33WKY/giphy.gif',
]
