import "share-api-polyfill";

import Vue from 'vue'

import VModal from 'vue-js-modal'
Vue.use(VModal)

import firebase from "firebase/app";

export default async ({ app }) => {
  // Set the function directly on the context.app object
  let response = await firebase.auth().signInAnonymously()
}
