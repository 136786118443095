<template>
  <footer class="mt-8 py-6 text-center text-white text-sm" style="background: #003939;">
    <slot></slot>
    <p class="mb-1">
      Powered by
      <a href="https://www.spaceride.de" target="_blank">
        spaceride
      </a>
      | Made in quarantine
    </p>
    <p class="m-0" style="color: #71A8A8;">
      <a href="https://form.brand-pioneers.de/emPOZ" target="_blank">
        Do you have any good question in mind? Share them with us!
      </a>
    </p>
  </footer>
</template>

<script>
  export default {

  }

</script>

<style>

</style>
