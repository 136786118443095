import firebase from 'firebase/app'

const config = {"apiKey":"AIzaSyAJfy-aOnhroZmPfV_YmHPdVHlaO31qG_U","authDomain":"pandemic-panda-production.firebaseapp.com","databaseURL":"https:\u002F\u002Fpandemic-panda-production.firebaseio.com","projectId":"pandemic-panda-production","storageBucket":undefined,"messagingSenderId":"388890240336","appId":"1:388890240336:web:7f96351df5c3fbea2c7855","measurementId":"G-ZD5N8JQS1R"}

export default async ({ res }, inject) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }
  const session = firebase.apps[0]

  /** --------------------------------------------------------------------------------------------- **/
  /** -------------------------------------- FIREBASE AUTH ---------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/auth')

  const fireAuth = session.auth()
  const fireAuthObj = firebase.auth
  inject('fireAuth', fireAuth)
  inject('fireAuthObj', fireAuthObj)

  /** --------------------------------------------------------------------------------------------- **/
  /** ------------------------------------ FIREBASE REALTIME DB ----------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  await import('firebase/database')

  const fireDb = session.database()
  const fireDbObj = firebase.database
  inject('fireDb', fireDb)
  inject('fireDbObj', fireDbObj)
}
