<template>
  <div class="flex flex-col">
    <div class="container flex-1 text-center text-white">

      <div class="md:w-1/2 lg:w-1/3 mx-auto">
        <img src="~assets/logo.svg" alt="Pandemic Panda Logo" class="logo">
        <template v-if="error.statusCode === 404 && error.message === 'empty_room'">


          <h1 class="mb-8">Empty Room</h1>
          <p class="mb-10">
            Looks like there's nobody left...maybe all of your friends are already drunk af
          </p>

          <img src="https://media.giphy.com/media/Dqyziru9dtHaw/giphy.gif" class="mx-auto mb-8">

          <nuxt-link to="/new-room" class="btn">
            Create a new one
          </nuxt-link>
        </template>

        <template v-else>
          <h1 class="mb-8">An error occurred</h1>
          <img src="https://media.giphy.com/media/Ty9Sg8oHghPWg/giphy.gif" class="mx-auto">
        </template>
      </div>
    </div>

    <the-footer></the-footer>

  </div>

</template>

<script>
  export default {
    props: ['error', 'message'],
    layout: 'blog' // you can set a custom layout for the error page
  }

</script>
