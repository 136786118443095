import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Vue from 'vue'

Vue.use(VueLodash, { lodash: lodash })

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox);

import TheFooter from '~/components/layout/TheFooter'
Vue.component('the-footer', TheFooter)

import ButtonLoading from '~/components/partials/ButtonLoading'
Vue.component('button-loading', ButtonLoading)

import TheTitle from '~/components/partials/TheTitle'
Vue.component('the-title', TheTitle)
