<template>
  <div>
    <img src="~assets/logo.svg" alt="Pandemic Panda Logo" class="logo">

    <h1 class="mb-8">
      <slot></slot>
    </h1>
  </div>
</template>

<script>
  export default {

  }

</script>

<style>

</style>
