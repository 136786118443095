<template>
  <button type="submit" ref="button" class="flex items-center justify-center" :style="btnStyle">
    <template v-if="!loading">
      <slot></slot>
    </template>
    <div class="lds-ring" v-if="loading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </button>
</template>

<script>
  export default {
    props: [
      'loading',
    ],
    data() {
      return {
        btnWidth: null,
      }
    },
    watch: {
      loading(newVal) {
        this.btnWidth = newVal ? this.$refs.button.offsetWidth : null;
      }
    },
    computed: {
      btnStyle() {
        if (!this.btnWidth) {
          return {}
        }
        return {
          'width': this.btnWidth + 'px',
        }
      }
    }
  }

</script>

<style lang="scss">
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    margin: 2px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

</style>
