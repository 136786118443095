import _ from "lodash";
import Questions from "~/assets/questions.json";
import Gifs from "../gifs";
import firebase from "firebase/app";
import {
  v4 as uuidv4
} from 'uuid';

export const state = () => ({
  room: {},
  roomId: null,
  user: {},
  usedGifs: [],
  isAfk: false,
});

export const mutations = {
  setRoom(state, room) {
    state.room = room;
  },
  setUser(state, user) {
    state.user = user;
  },
  setRoomId(state, roomId) {
    state.roomId = roomId;
  },
  setNewGif(state, index) {
    this.state.usedGifs.push(index);
  },
  resetGifs(state, index) {
    this.state.usedGifs = [];
  },
  setAfk(state, value) {
    this.state.isAfk = value;
  },
};

export const actions = {
  async getRoomOnce({ commit }, roomId) {
    let snapshot = await this.$fireDb.ref("rooms/" + roomId).once("value");
    commit("setRoom", snapshot.val());

    return snapshot.val();
  },
  getRoomEvent({ commit }, roomId) {
    this.$fireDb.ref("rooms/" + roomId).on("value", snapshot => {
      commit("setRoom", snapshot.val());
    });
  },
  async deleteMe({ commit, getters }) {
    await this.$fireDb
      .ref("rooms/" + this.getters.roomId + "/members/" + this.getters.myId)
      .update({
        deleted_at: firebase.database.ServerValue.TIMESTAMP
      });
  },
  async kickUser({ commit, getters }, { userId }) {
    await this.$fireDb
      .ref("rooms/" + this.getters.roomId + "/members/" + userId)
      .update({
        deleted_at: firebase.database.ServerValue.TIMESTAMP
      });
  },
  async makeNextMemberKing({ getters }) {
    if (!getters.areOtherMembersPresent) {
      return false;
    }

    let nextKing = Object.keys(this.getters.otherMembers)[0];

    let update = {};
    update[nextKing] = {
      ...getters.members[nextKing],
      ...{
        is_king: true
      }
    };

    update[this.getters.myId] = {
      ...getters.members[this.getters.myId],
      ...{
        is_king: false
      }
    };

    await this.$fireDb
      .ref("rooms/" + this.getters.roomId + "/members")
      .update(update);
  },
  async chooseUser({ getters }, { userId }) {
    if (getters.selectionId) {
      return await this.$fireDb
        .ref(
          "rooms/" +
            getters.roomId +
            "/questions/" +
            getters.currentQuestionId +
            "/answers/" +
            getters.selectionId
        )
        .update({
          chosenId: userId
        });
    }

    return await this.$fireDb
      .ref(
        "rooms/" +
          getters.roomId +
          "/questions/" +
          getters.currentQuestionId +
          "/answers"
      )
      .push({
        memberId: getters.myId,
        chosenId: userId
      });
  },
  async setNewQuestion({ getters, dispatch, state }) {
    let randomQuestionIndex = null;
    while (
      randomQuestionIndex === null ||
      getters.alreadyAnsweredQuestionIndexes.includes(randomQuestionIndex)
    ) {
      randomQuestionIndex = Math.round(Math.random() * (Questions.length - 1));
    }

    dispatch("giveNewGif");

    return await this.$fireDb
      .ref("rooms/" + getters.roomId + "/questions")
      .push({
        index: randomQuestionIndex,
        gif: state.usedGifs[state.usedGifs.length - 1]
      });
  },
  async login({ commit }, { roomId, user }) {
    user.deleted_at = null;
    user.created_at = firebase.database.ServerValue.TIMESTAMP

    let response = await this.$fireDb.ref("rooms/" + roomId + "/members").push(user);

    user.id = response.key;
    commit('setUser', user)

    return response
  },
  async createRoom({ getters }, { videolink }) {
    let id = uuidv4();
    let room = {}
    if (videolink) {
      room.videolink = videolink
    }

    await this.$fireDb.ref("rooms/" + id).set(room);

    return id
  },
  giveNewGif({ state, commit }) {
    if (Gifs.length === state.usedGifs.length) {
      commit("resetGifs");
    }

    let randomIndex = null;
    while (randomIndex === null || state.usedGifs.includes(randomIndex)) {
      randomIndex = Math.round(Math.random() * (Gifs.length - 1));
    }

    commit("setNewGif", randomIndex);
  },
  async logout({ dispatch, getters }) {
    if (getters.isLoggedIn) {

      if (getters.isKing) {
        // make next user king if there is one
        await dispatch('makeNextMemberKing')
      }

      // delete from members object
      await dispatch('deleteMe');
    }
  },
  async goAfk({ commit, getters }) {
    commit('setAfk', true);

    await this.$fireDb
      .ref("rooms/" + this.getters.roomId + "/members/" + this.getters.myId)
      .update({
        afk_at: firebase.database.ServerValue.TIMESTAMP
      });
  },
  async disableAfk({ commit, getters }) {

    await this.$fireDb
      .ref("rooms/" + this.getters.roomId + "/members/" + this.getters.myId + '/afk_at')
      .remove();

    commit('setAfk', false);
  },
};

export const getters = {
  // user
  myId: (state, getters) => state.user.id,
  isKing(state, getters) {
    if (!getters.members.hasOwnProperty(getters.myId)) {
      return false;
    }
    return getters.members[getters.myId].is_king;
  },
  isNotKing(state, getters) {
    return !getters.isKing;
  },
  isLoggedIn(state) {
    return Object.keys(state.user).length !== 0;
  },
  isNotLoggedIn(state) {
    return Object.keys(state.user).length === 0;
  },
  isAfk: (state) => state.isAfk,
  isNotAfk: (state, getters) => !getters.isAfk,

  // room
  roomId: state => state.roomId,
  room: state => state.room,
  videoChat: (state, getters) => getters.room.videolink,
  hasVideoChat: (state, getters) => getters.videoChat !== undefined,

  // room members
  totalMembers: state => state.room.members || {},
  members(state) {
    return Object.keys(state.room.members || {})
      .filter(key => state.room.members[key].deleted_at === undefined)
      .filter(key => state.room.members[key].afk_at === undefined)
      .reduce((obj, key) => {
        obj[key] = state.room.members[key];
        return obj;
      }, {});
  },
  member: (state, getters) => memberId => getters.totalMembers[memberId],
  otherMembers(state, getters) {
    return Object.keys(getters.members)
      .filter(key => key !== state.user.id)
      .reduce((obj, key) => {
        obj[key] = getters.members[key];
        return obj;
      }, {});
  },
  memberIds: (state, getters) => Object.keys(getters.members),
  areOtherMembersPresent: (state, getters) =>
    Object.keys(getters.otherMembers).length > 0,
  roomIsEmpty: (state, getters) => Object.keys(getters.members).length === 0,
  membersCount: (state, getters) => Object.keys(getters.members).length,
  kingMemberId(state, getters) {
    return _.findKey(getters.totalMembers, "is_king");
  },
  kingMember: (state, getters) => getters.member(getters.kingMemberId),
  membersSortByKing(state, getters) {
    return _.sortBy(Object.keys(getters.members), "is_king").reduce(
      (obj, key) => {
        obj[key] = getters.members[key];
        return obj;
      },
      {}
    );
  },
  isNameAlreadyTaken: (state, getters) => name =>
    Object.values(getters.otherMembers).some(user => user.name === name),

  // questions
  questions: state => (state.room ? state.room.questions : undefined),
  hasQuestions: (state, getters) => typeof getters.questions === "object",
  hasNoQuestions: (state, getters) => !getters.hasQuestions,
  currentQuestionId: (state, getters) => _.findLastKey(getters.questions),
  currentQuestion: (state, getters) =>
    getters.questions
      ? getters.questions[getters.currentQuestionId]
      : undefined,
  currentQuestionText: (state, getters) =>
    getters.questions
      ? Questions[getters.currentQuestion.index].text
      : undefined,
  alreadyAnsweredQuestionIndexes: (state, getters) =>
    _.flatMap(getters.questions, "index"),
  selectionId: (state, getters) =>
    getters.currentQuestion && getters.currentQuestion.answers
      ? _.findKey(getters.currentQuestion.answers, ["memberId", getters.myId])
      : undefined,
  selectedMemberId: (state, getters) =>
    getters.selectionId
      ? getters.currentQuestion.answers[getters.selectionId].chosenId
      : undefined,
  mySelectionMade: (state, getters) => getters.selectedMemberId !== undefined,
  mySelectionNotMade: (state, getters) => !getters.mySelectionMade,

  membersThatHaveNotMadeASelection(state, getters) {
    let members = Object.keys(getters.members);
    if (!getters.currentQuestion.answers) {
      return [];
    }

    let alreadyAnsweredIds = Object.entries(
      getters.currentQuestion.answers
    ).map(item => item[1].memberId);

    return members.filter(memberId => !alreadyAnsweredIds.includes(memberId));
  },
  membersThatHaveMadeASelection(state, getters) {
    let members = Object.keys(getters.members);
    if (!getters.currentQuestion.answers) {
      return [];
    }

    let alreadyAnsweredIds = Object.entries(
      getters.currentQuestion.answers
    ).map(item => item[1].memberId);

    return members.filter(memberId => alreadyAnsweredIds.includes(memberId));
  },

  allMembersMadeSelection(state, getters) {
    let members = Object.keys(getters.members);
    if (!getters.currentQuestion.answers) {
      return false;
    }

    let alreadyAnsweredIds = Object.entries(
      getters.currentQuestion.answers
    ).map(item => item[1].memberId);

    return (
      members.filter(memberId => !alreadyAnsweredIds.includes(memberId))
        .length === 0
    );
  },
  notAllMembersMadeSelection: (state, getters) =>
    !getters.allMembersMadeSelection,
  /**
   * Returns the members with their id and the times that they are chosen
   * @param {*} state
   * @param {*} getters
   */
  memberSelectionCount(state, getters) {
    let chosenIds = Object.entries(getters.currentQuestion.answers).map(
      item => item[1].chosenId
    );

    return _.countBy(chosenIds);
  },
  /**
   * Returns the sorted memberSelectionCount
   * @param {*} state
   * @param {*} getters
   */
  memberSelectionCountSorted(state, getters) {
    return Object.keys(getters.memberSelectionCount).sort((a, b) => {
      return getters.memberSelectionCount[b] - getters.memberSelectionCount[a];
    });
  },
  /**
   * Returns an array of the members ID's that have been chosen the most. It's a draw if the array has more than one item
   * @param {*} state
   * @param {*} getters
   */
  highestMemberInSelectionId(state, getters) {
    let first =
      getters.memberSelectionCount[getters.memberSelectionCountSorted[0]];

    return getters.memberSelectionCountSorted.filter(
      item => getters.memberSelectionCount[item] === first
    );
  },
  /**
   * Returns an array of member names that have been chosen
   * @param {*} state
   * @param {*} getters
   */
  highestMemberNamesInSelection(state, getters) {
    return getters.highestMemberInSelectionId
      .map(memberId => {
        if (getters.member(memberId)) {
          return getters.member(memberId).name;
        }

        return null;
      })
      .filter(Boolean)
      .sort();
  },

  highestMemberNamesInSelectionJoined: (state, getters) =>
    getters.highestMemberNamesInSelection.join(" & "),

  isDraw: (state, getters) => getters.highestMemberNamesInSelection.length > 1,
  isNotDraw: (state, getters) => !getters.isDraw,

  everybodyIsSelected: (state, getters) =>
    getters.highestMemberNamesInSelection.length === getters.membersCount,
  notEverybodyIsSelected: (state, getters) => !getters.everybodyIsSelected,

  isGameRunning: (state, getters) =>
    getters.hasQuestions &&
    getters.isLoggedIn &&
    getters.areOtherMembersPresent,
  isGameNotRunning: (state, getters) => !getters.isGameRunning
};
