import _ from "lodash";
import Questions from "~/assets/questions.json";

export const state = () => ({
  room: {}
})

export const mutations = {
  setRoom(state, room) {
    state.room = room;
  },
}

export const actions = {
  async getRoomOnce({ commit }, roomId) {
    let snapshot = await this.$fireDb.ref("rooms/" + roomId).once("value");
    commit("setRoom", snapshot.val());

    return snapshot.val();
  },
  getRoomEvent({ commit }, roomId) {
    this.$fireDb.ref("rooms/" + roomId).on("value", snapshot => {
      commit("setRoom", snapshot.val());
    });
  },
}

export const getters = {
  room: state => state.room,
  totalMembers: (state) => state.room.members || {},
  totalMembersCount: (state, getters) => Object.keys(getters.totalMembers).length,
  member: (state, getters) => memberId => getters.totalMembers[memberId],
  questions: state => (state.room ? state.room.questions : undefined),
  questionsCount: (state, getters) => Object.keys(getters.questions).length,
  getQuestionText: (state, getters) => questionIndex => Questions[questionIndex] ? Questions[questionIndex].text : null,
  getMemberName: (state, getters) => memberId => getters.totalMembers[memberId] ? getters.totalMembers[memberId].name : null,

  // result
  /**
   * Returns the members with their id and the times that they are chosen
   * @param {*} state
   * @param {*} getters
   */
  memberSelectionCount: (state, getters) => questionKey => {

    if (!getters.questions[questionKey].answers) {
      return {}
    }

    let chosenIds = Object.entries(getters.questions[questionKey].answers).map(
      item => item[1].chosenId
    );

    return _.countBy(chosenIds);
  },
  /**
   * Returns the sorted memberSelectionCount
   * @param {*} state
   * @param {*} getters
   */
  memberSelectionCountSorted: (state, getters) => questionKey => {
    return Object.keys(getters.memberSelectionCount(questionKey)).sort((a, b) => {
      return getters.memberSelectionCount[b] - getters.memberSelectionCount[a];
    });
  },
  /**
   * Returns an array of the members ID's that have been chosen the most. It's a draw if the array has more than one item
   * @param {*} state
   * @param {*} getters
   */
  highestMemberInSelectionId: (state, getters) => questionKey => {

    let count = getters.memberSelectionCount(questionKey)
    let sorted = getters.memberSelectionCountSorted(questionKey)

    let first = count[sorted[0]];

    return sorted.filter(
      item => count[item] === first
    );
  },
  /**
   * Returns an array of member names that have been chosen
   * @param {*} state
   * @param {*} getters
   */
  highestMemberNamesInSelection: (state, getters) => questionKey => {
    return getters.highestMemberInSelectionId(questionKey)
      .map(memberId => {
        if (getters.member(memberId)) {
          return getters.member(memberId).name;
        }

        return null;
      })
      .filter(Boolean)
      .sort();
  },

  highestMemberNamesInSelectionJoined: (state, getters) => questionKey => getters.highestMemberNamesInSelection(questionKey).join(" & "),

  sortAnswersByName: (state, getters) => answers => {

    if (!answers) {
      return []
    }

    let names = Object.entries(answers).map(answer => {
      return [
        getters.getMemberName(answer[1].memberId),
        getters.getMemberName(answer[1].chosenId),
      ]
    })

    return _.sortBy(names, [function(o) { return o[0]; }])
  }
}
